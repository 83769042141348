import App from '../modules/app.js';

/**
 * The BaseComponent defines a common structure and functionality for all user-interface components used in the app.
 * The extending component should override the `enable`, `disable`, `show`, and `hide` functions as necessary to 
 * adjust the state of the component. 
 * @abstract
 */
class BaseComponent {
    /**
     * Constructs a new instance of the `BaseComponent`.
     * @param {*} defaults - Optional default settings for the component. These are applied to the `settings` property
     * of the component and stored in the `defaults` property. See also: `resetToDefaults` function.
     */
    constructor(defaults) {

        /**
         * @type {BaseComponent}
         */
        this.parent = null;

        /**
         * @type {Object}
         */
        this.defaults = defaults || {};

        /**
         * @type {Object}
         */
        this.settings = this.resetToDefaults();

        /**
         * @type {App}
         * @protected
         */
        this.app = App || window.app;

        /**
         * @type {Map.<string, BaseComponent>}
         */
        this.components = new Map();

        /**
         * @type {Boolean}
         * @private
         */
        this._enabled = true;

        /**
         * @type {Boolean}
         * @private
         */
        this._visible = true;

        /**
         * @type {Boolean}
         * @private
         */
        this._readied = false;

        this.readyBind();
    }

    /**
     * @type {Boolean}
     */
    get enabled() {
        return (this._enabled || false);
    }

    /**
     * @param {Boolean} v - Value of enabled.
     */
    set enabled(v) {
        if (v) {
            this.enable();
        } else {
            this.disable();
        }
    }

    /**
     * @type {Boolean}
     */
    get visible() {
        return (this._visible || false);
    }

    /**
     * @param {Boolean} v - Value of visible.
     */
    set visible(v) {
        if (v) {
            this.show();
        } else {
            this.hide();
        }
    }

    /**
     * @type {Boolean}
     */
    get readied() {
        return (this._readied || false);
    }

    /**
     * Performs the binding of the `ready` function call from the DOM "ready" jQuery event.
     * This should set the component `readied` property to `true`.
     * @protected
     */
    readyBind() {
        jQuery(() => {
            this._readied = true;
            this.ready();
        });
    }

    /**
     * Destroy the components and their state. A caller should be able to call this then the `ready` function
     * to completely reset and ready the component.
     * @abstract
     */
    destroy() {}

    /**
     * Called when the DOM is ready and this component is usable and loaded.
     * @abstract
     */
    ready() {}

    /**
     * This function is called whenever a settings property is changed on the component.
     * @param {String} prop - The setting property/key being affected.
     * @param {*} value - The value attempting to be set.
     * @param {Promise} done - This promise resolves or rejects after the setting value has been set (or not).
     * @listens Listens to `settings` object property value changes.
     * @returns {*} Returns the value to be set. A `value` of undefined leaves the stored setting value as-is.
     * @abstract
     */
    settingChange(prop, value, done) { return value; }

    /**
     * Resets the settings of the component to their defaults.
     * @returns {*} Returns the updated settings.
     */
    resetToDefaults() {
        let handler = {};
        handler.set = (obj, prop, value) => {
            let resolve, reject;
            let done = new Promise((res, rej) => {
                resolve = res;
                reject = rej;
            });
            value = this.settingChange(prop, value, done);
            if (typeof value !== 'undefined') {
                obj[prop] = value;
                resolve(prop, value);
            } else {
                reject();
            }
            return true;
        };
        this.settings = new Proxy(Object.assign({}, this.defaults), handler);
        return this.settings;
    }

    /**
     * Adds a component to the view. This should be called only after the `ready` function has fired.
     * @param {String} name - The name of the component.
     * @param {BaseComponent} component - The component instance.
     * @returns {this} The current view instance.
     */
    addComponent(name, component) {
        if (this.components.has(name)) {
            throw new Error(`A component with the name "${name}" already exists.`);
        } else if (!component) {
            throw new Error('The parameter "component" is required.');
        } else if ((component instanceof BaseComponent) === false) {
            throw new Error('The argument for the "component" parameter must be an instance of BaseComponent');
        }
        component.parent = this;
        this.components.set(name, component);
        return this;
    }

    /**
     * Removes a component from the view.
     * @param {String} name - The name of the component.
     * @returns {Boolean}
     */
    removeComponent(name) {
        if (this.components.has(name)) {
            this.components.get(name).parent = null; //remove as parent
            return this.components.delete(name);
        }
        return false;
    }

    /**
     * Enabled the component.
     * @returns {this} The current component instance.
     * @abstract
     */
    enable() {
        this._enabled = true;
        return this;
    }

    /**
     * Disable the component.
     * @returns {this} The current component instance.
     * @abstract
     */
    disable() {
        this._enabled = false;
        return this;
    }

    /**
     * Refresh the component by first destorying it then re-readying it.
     * @returns {this} The current component instance.
     * @abstract
     */
    refresh() {
        if (this.readied) {
            this.destroy();
            this.ready();
        }
        return this;
    }

    /**
     * Show the component - make it visible on-screen.
     * @returns {this} The current component instance.
     * @abstract
     */
    show() {
        this._visible = true;
        return this;
    }

    /**
     * Hide the component - make it invisible on-screen.
     * @returns {this} The current component instance.
     * @abstract
     */
    hide() {
        this._visible = false;
        return this;
    }

    /**
     * Make a standardized AppKu AJAX request to the server using a 'POST' (by default) method.
     * This includees the CSRF token in every request.
     * @param {String} path - The URL web path. Can be relative or absolute.
     * @param {*} [data] - Any data payload to send.
     * @param {String} [method] - The HTTP method for the request to use, such as 'GET', 'POST' (default), 'PUT', or 'DELETE'.
     * @param {*} [settings] - Settings passed to the JQuery AJAX call (overrides auto-determined).
     * @returns {Promise}
     * @protected
     */
    ajax(path, data, method, settings) {
        let baseURL = this.site.workingURL;
        let csrf = this.site.csrf;
        if (path.match(/^http/i)) {
            baseURL = '';
        }
        if (data && method && method.match(/delete/i)) {
            let sep = path.match(/.+\?.*/) ? '&' : '?';
            path = `${path}${sep}${$.param(data)}`;
            data = null;
        }
        //make the call
        return new Promise((resolve, reject) => {
            $.ajax(Object.assign({
                url: baseURL + path,
                traditional: true,
                method: method || 'POST',
                headers: { 'X-CSRF-Token': csrf },
                data: data,
                success: (data) => {
                    resolve(data);
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    console.error('AppKu™ AJAX Failure', textStatus, errorThrown, jqXHR);
                    reject(jqXHR);
                }
            }, settings));
        });
    }
}

export default BaseComponent;