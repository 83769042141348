/**
 * The `App` module holds the initialized settings of the application.
 * @module
 */
let App = {

    /**
     * The URL of the base of the site.
     * @type {String}
     */
    get baseURL() {
        return $('meta[name="_baseURL"]').attr('content');
    },

    /**
     * The URL of the either the base of the site, or the base repo URL (if viewing a org/user repo).
     * @type {String}
     */
    get workingURL() {
        return $('meta[name="_workingURL"]').attr('content');
    },

    /**
     * The form Cross-Site Request Forgery token.
     * @type {String}
     */
    get csrf() {
        return $('meta[name="_csrf"]').attr('content');
    }
    
}

export default App;