import BaseDOMComponent from '../../components/base-dom-component.js';

/**
 * This view is initialized for all DOM pages.
 */
class LayoutView extends BaseDOMComponent {
    /**
     * Constructs a new instance of the `BaseView`.
     * @param {*} defaults - Optional default settings for the component. These are applied to the `settings` property
     * of the component and stored in the `defaults` property. See also: `resetToDefaults` function.
     */
    constructor(defaults) {
        super(Object.assign({
            $container: 'body'
        }, defaults));
    }

    /**
     * @override
     */
    ready() {
    }

}

export default LayoutView; 