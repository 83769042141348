import BaseComponent from './base-component.js';
/**
 * The base class that provides functionality for the creation and management of DOM-based components.
 */
class BaseDOMComponent extends BaseComponent {

    /**
     * Constructs a new instance of the `BaseContainerComponent`.
     * @param {DOMComponentSettings} defaults - Optional default settings for the component. These are applied to the
     * `settings` property of the component and stored in the `defaults` property.
     */
    constructor(defaults) {
        super(Object.assign({
            autoDestroy: true,
            autoRefresh: true
        }, defaults));

        /**
         * @type {DOMComponentSettings}
         */
        this.settings;

        //init
        this.resizeBind();
        this.printBind();
    }

    /**
     * @override
     * @abstract
     */
    destroy() {
        let $c = this.$container;
        if ($c && this.settings.autoDestroy) {
            //destroy kendo components.
            if (window.kendo) {
                let kendoComponents = [];
                $c.find(`[data-${window.kendo.ns}role]`).each((i, el) => {
                    let data = $(el).data();
                    for (let p in data) {
                        if (p.indexOf('kendo') === 0 && kendoComponents.indexOf(el) < 0) {
                            kendoComponents.push(el);
                        }
                    }
                });
                kendoComponents.map(v => $(v).empty());
                window.kendo.destroy($c);
            }
        }
    }

    /**
     * Binds the window "resize" events to the `resize` function.
     * @protected
     */
    resizeBind() {
        $(window).on('resize', () => {
            if (this.readied) {
                this.resize();
            }
        });
    }

    /**
     * Called whenever the browser window is resized.
     * This method is only called after the component is ready.
     * @listens
     * @virtual
     */
    resize() { }

    /**
     * Binds the window "beforeprint" and "afterprint" events to the `print` function.
     * @protected
     * @abstract
     */
    printBind() {
        $(window).on('beforeprint', () => {
            if (this.readied) {
                this.print(false);
            }
        });
        $(window).on('afterprint', () => {
            if (this.readied) {
                this.print(true);
            }
        });
    }

    /**
     * Called before printing occurs.
     * This method is only called after the component is ready.
     * @param {Boolean} after - A `true` value indicates printing has completed. A `false` is before printing.
     * @listens
     * @virtual
     */
    print(after) { }

    /**
     * The selector pointing to the DOM element containing the view.
     * This is only set when the document is ready (via the `ready` function).
     * 
     * This is a shortcut to the `settings.$container` property.
     * @type {JQuery.<HTMLElement>}
     * @readonly
     */
    get $container() {
        return this.settings?.$container || null;
    }

    /**
     * @override
     */
    readyBind() {
        jQuery(() => {
            //re-set the container on ready if it wasn't matched - there is a chance the container is not actually ready prior to this.
            let $c = this.settings.$container;
            if ($c && (($c instanceof jQuery) === false || $c.length === 0)) { //ensure we set this to a jquery object.
                this.settings.$container = this.defaults.$container;
            }
            if ((this.$container instanceof jQuery) && !!this.$container?.length) {
                this._readied = true; //private prop needs to be set on ready
                this.ready();
            }
        });
    }

    /**
     * @override
     * @param {String} prop 
     * @param {*} value 
     * @param {Promise} done
     * @listens Listens to `settings` object property value changes.
     * @abstract
     */
    settingChange(prop, value, done) {
        if (prop === '$container') {
            if (this.readied && this.settings.autoRefresh) {
                //destroy the existing component on the current container, prior to setting the new one.
                this.destroy();
                //call ready when we're done setting the new container.
                done.then(() => {
                    this.ready();
                });
            }
            return $(value);
        }
    }

    /**
     * @override
     */
    hide() {
        this.$container.hide();
        super.hide();
        return this;
    }

    /**
     * @override
     */
    show() {
        this.$container.show();
        super.show();
        return this;
    }
}

export default BaseDOMComponent;